import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

import { colors } from '../styles'

import Branding from './common/Branding'

const H = styled.header`
	display: flex;
	align-items: center;
	background: rgba(255, 255, 255, 0.5);
	.branding {
		fill: ${colors.green};
		height: 70px;
		margin: 1rem;
		margin-right: 4px;
	}
	h1 {
		font-size: 1rem;
		padding: 2rem 0;
		letter-spacing: 2px;
		color: ${colors.lightGrey};
		/* word-spacing: 2000px; */
	}
`

const Header = ({ siteTitle }) => (
	<H>
		<Branding />
		<h1>{siteTitle}</h1>
	</H>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
