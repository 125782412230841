/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import GlobalStyles from '../styles/global-styles'

import { sizes, colors } from '../styles'

import Header from './header'
import Bg from './common/Branding'

const Wrap = styled.div`
	& > .branding {
		fill: ${colors.paleGreen};
		position: fixed;
		top: 50%;
		left: 0;
		height: 100vh;
		transform: translate3d(-10%, -50%, 0);
		z-index: -1;
		@media only screen and (min-width: ${sizes.sdesk}) {
			display: none;
		}
	}
	main {
		min-height: 80vh;
		padding: 0 ${sizes.gutterMobile};
		padding-top: 10vh;
		@media only screen and (min-width: ${sizes.tablet}) {
			padding-left: 5vw;
		}
		@media only screen and (min-width: ${sizes.sdesk}) {
			padding-top: 15vh;
			padding-left: 30vw;
		}
	}
	.sunflower {
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: -1;
		width: 80vw;
		transform: translate3d(0, 40%, 0);
		@media only screen and (min-width: ${sizes.tablet}) {
			width: 50vw;
		}
		@media only screen and (min-width: ${sizes.sdesk}) {
			width: 42vw;
		}
	}
	footer {
		.copyright {
			background: rgba(255, 255, 255, 0.9);
			text-align: center;
			color: ${colors.lightGrey};
			position: fixed;
			padding: 0.5rem;
			bottom: 0;
			left: 0;
			width: 100%;
		}
	}
`

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<Wrap>
			<GlobalStyles />
			<Bg />
			<Header siteTitle={data.site.siteMetadata.title} />
			<main>{children}</main>
			<footer>
				<div className='copyright'>copyright © Madze Creations LLC 2020</div>
			</footer>
		</Wrap>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
